const Accordion = {
  baseStyle: {
    root: {
      borderColor: 'border.secondary',
      transition: 'ease-in-out 0.2s',
    },
    container: {
      border: 'none',
    },
    button: {
      px: '0',
      backgroundColor: 'transparent',
      fontWeight: 'medium',
      fontSize: 'lg',
      textColor: 'primary.900',
      textAlign: 'left',
      _active: {
        textColor: 'content.highlight-brand',
        backgroundColor: 'transparent',
      },
      _expanded: {
        textColor: 'content.highlight-brand',
        backgroundColor: 'transparent',
        '.chakra-accordion__icon': {
          transform: 'rotate(45deg)',
        },
      },
      _hover: {
        backgroundColor: 'transparent',
      },
    },
    panel: {
      px: 0,
    },
    icon: {
      fill: 'content.secondary',
    },
  },
}

export default Accordion

import {
  Box,
  Grid,
  GridItem,
  Heading,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ReactComponent as TeleClinicLogoIcon } from 'assets/icons/TeleClinicLogo.svg'
import { Footer } from 'components/presentational'

export function Imprint() {
  return (
    <Grid
      flex="1"
      minH="100dvh"
      templateRows="1fr auto"
      templateAreas={`"main" "footer"`}
      bg="neutral.50"
    >
      <GridItem
        gridArea="main"
        placeSelf="center"
        p={{ base: 6, md: 16 }}
        maxW="container.md"
      >
        <Icon
          as={TeleClinicLogoIcon}
          mb="20"
          w="100px"
          h="auto"
          fill="primary.500"
        />
        <Heading variant="headline.lg.medium" mb="10">
          Impressum TeleClinic Videosprechstunden
        </Heading>

        <VStack spacing="10" align="start">
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              Dienstanbieter
            </Heading>
            <Text mb="4">
              Dienstanbieter dieses Onlineangebotes und gemäß § 5 DDG für den
              Inhalt dieser Site verantwortlich ist:
            </Text>
            <Text>
              TeleClinic GmbH, <br />
              c/o Design Offices München, <br />
              Brienner Straße 45a-d,
              <br />
              80333 München
            </Text>
          </Box>
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              Vetretung
            </Heading>
            <Text mb="4">
              Vertreten durch die Geschäftsführer Benedikt Luber und Julian
              Simon
            </Text>
            <Box>
              <Text>
                <Text as="span" variant="body.md.medium">
                  E-Mail
                </Text>
                :{' '}
                <Link color="primary.500" href="mailto:info@teleclinic.com">
                  info@teleclinic.com
                </Link>
              </Text>
              <Text>
                <Text as="span" variant="body.md.medium">
                  Web
                </Text>
                :{' '}
                <Link color="primary.500" href="www.teleclinic.com">
                  www.teleclinic.com
                </Link>
              </Text>
              <Text>
                <Text as="span" variant="body.md.medium">
                  Sitz der Gesellschaft
                </Text>
                : München
              </Text>
              <Text>Amtsgericht München</Text>
              <Text>
                <Text as="span" variant="body.md.medium">
                  Registernummer
                </Text>
                : HRB 223627
              </Text>
              <Text>
                <Text as="span" variant="body.md.medium">
                  Umsatzsteuer-Identifikationsnummer
                </Text>
                : DE305174929
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              Streitbeilegung
            </Heading>
            <Text mb="4">
              Die EU-Kommission hat eine Internetseite zur
              Online-Streitbeilegung zwischen Unternehmern und Verbrauchern
              eingerichtet, die Sie unter{' '}
              <Link
                color="primary.500"
                href="https://ec.europa.eu/consumers/odr/"
              >
                https://ec.europa.eu/consumers/odr/
              </Link>{' '}
              erreichen.
            </Text>
            <Text>
              Die TeleClinic GmbH ist zur Teilnahme an einem
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              weder bereit noch dazu verpflichtet.
            </Text>
          </Box>
          <Box>
            <Text mb="4">
              Editorisch und verantwortlich für den Inhalt gemäß § 18 Abs. 2
              MStV. <br />
              Redaktionell und inhaltlich verantwortlich ist die TeleClinic
              GmbH.
            </Text>
          </Box>
        </VStack>
      </GridItem>
      <GridItem gridArea="footer">
        <Footer />
      </GridItem>
    </Grid>
  )
}

import {
  LocationContext,
  NetworkContext,
  UserContextProvider,
} from 'components/contextProvider'
import ErrorBoundary from 'components/view/ErrorBoundary'
import CreateAppointment from 'modules/CreateAppointment'
import { Dashboard } from 'modules/Dashboard'
import { Home } from 'modules/Home/Home'
import { Imprint, Privacy } from 'modules/Legal'
import { Lobby } from 'modules/Lobby'
import PostVideoCall from 'modules/PostVideoCall'
import { Room } from 'modules/Room'
import SignIn from 'modules/SignIn'
import WaitingRoom from 'modules/WaitingRoom'
import { Suspense, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { queryParams } from 'utils/utils'

function Router() {
  const { isAuthenticated, setAuth, removeAuth } = useContext(NetworkContext)
  const { filter, page } = useContext(LocationContext)
  const accessToken = queryParams('accessToken')
  const expirationTime = queryParams('expirationTime')
  const redirect = queryParams('redirect')
  const { localStorage } = window
  let defaultRoute = `/appointment?filter=${filter}&page=${page}`
  const currentAccessToken = localStorage.getItem('accessToken')
  const currentAppointment = localStorage.getItem('currentAppointment')
  const waitingRoom = `/appointment/${currentAppointment}`

  if (
    accessToken !== null &&
    accessToken !== currentAccessToken &&
    isAuthenticated
  ) {
    removeAuth()
    return null
    /* eslint-disable no-else-return */
  } else if (accessToken !== null && !isAuthenticated) {
    const payload = {
      access_token: accessToken,
      expires_in: expirationTime,
      refresh_token: null,
    }
    setAuth(payload)
    return null
  }

  if (redirect !== null) {
    defaultRoute = redirect
  }
  if (currentAppointment !== null && window.location.pathname === '/room') {
    defaultRoute = waitingRoom
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isAuthenticated ? (
        <UserContextProvider>
          <ErrorBoundary>
            <Suspense fallback={() => null}>
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/room" element={<Room />} />
                <Route path="/finished" element={<PostVideoCall />} />
                <Route path="/create" element={<CreateAppointment />} />
                <Route path="/appointment" element={<Dashboard />} />
                <Route
                  path="/waiting-room/:appointmentId"
                  element={<WaitingRoom />}
                />
                <Route path="/" element={<Navigate to={defaultRoute} />} />
                <Route
                  path="/signin"
                  element={<Navigate to={defaultRoute} />}
                />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </UserContextProvider>
      ) : (
        <UserContextProvider>
          <ErrorBoundary>
            <Suspense fallback={() => null}>
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/room" element={<Room />} />
                <Route path="/finished/*" element={<PostVideoCall />} />
                <Route path="/lobby/*" element={<Lobby />} />
                <Route path="/signin/*" element={<SignIn />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </UserContextProvider>
      )}
    </>
  )
}

export default Router

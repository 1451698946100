import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  ListItem,
  OrderedList,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { ReactComponent as Calendar } from 'assets/icons/Calendar.svg'
import { ReactComponent as Chat } from 'assets/icons/Chat.svg'
import { ReactComponent as Logo } from 'assets/icons/Logo.svg'
import { ReactComponent as LogoLockUp } from 'assets/icons/LogoLockUp.svg'
import { ReactComponent as Video } from 'assets/icons/Video.svg'
import moment from 'moment'
import { ActionButton, Navigation, Section } from './elements'

export function Home() {
  return (
    <Flex direction="column">
      <Section pb={{ base: 0, md: 0 }} pt={{ base: 12, md: 12 }}>
        <Flex justify="space-between" align="baseline">
          <Icon as={Logo} w="160px" h="auto" fill="primary.500" />
          <Navigation />
        </Flex>
      </Section>

      {/* HERO SECTION */}
      <Section>
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          gap={{ base: 8, md: 16 }}
          alignItems="center"
        >
          <VStack>
            <Heading color="primary.900" variant="headline.2xl.medium" pr="6">
              Zertifizierte Videosprechstunde zwischen Ärzten und Patienten
            </Heading>
            <Text mb="6" fontSize="xl">
              teleclinic.video bietet Ärzten und Patienten eine zertifizierte
              Videosprechstunde zur Durchführung von digitalen Fernbehandlungen.
            </Text>
            <Flex
              gap="2"
              align="stretch"
              direction={{ base: 'column', md: 'row' }}
              alignSelf="stretch"
            >
              <ActionButton
                scheme="gray"
                subtitle="Arzt mit Account?"
                title="Zum Login"
                link="/signin"
              />
              <ActionButton
                subtitle="Patient mit TAN-Einladung?"
                title="Zur Videosprechstunde"
                link="/lobby"
              />
            </Flex>
          </VStack>
          <Image
            src="/img/teleclinic-video-call.jpg"
            alt="Teleclinic Video Hero image"
            borderRadius="2xl"
          />
        </SimpleGrid>
      </Section>

      {/* ABOUT SECTION */}
      <Section bg="neutral.50" id="about">
        <VStack gap={{ base: 8, md: 12 }} py="4">
          <Heading
            align="center"
            color="primary.900"
            variant="headline.lg.medium"
          >
            So kann die Videosprechstunde ablaufen
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: 8, md: 16 }}>
            <VStack gap="4" textAlign="center">
              <Icon as={Calendar} w="50px" h="auto" fill="primary.900" mb="2" />
              <Heading color="primary.900" as="h5" variant="headline.xs.medium">
                1. Terminierung und Zugangsdaten
              </Heading>
              <Text>
                Patienten erhalten von der Arztpraxis einen Termin und die
                Einwahldaten (TAN) für die Videosprechstunde. So können
                Patienten an der Videosprechstunde teilnehmen, ohne dass Sie
                einen eigenen Account anlegen müssen.
              </Text>
            </VStack>
            <VStack gap="4" textAlign="center">
              <Icon as={Video} w="50px" h="auto" fill="primary.900" mb="2" />
              <Heading color="primary.900" as="h5" variant="headline.xs.medium">
                2. Videosprechstunde beitreten
              </Heading>
              <Text>
                Zum Termin der Videosprechstunde wählen sich Patient und Arzt
                einige Minuten vor dem Termin mit den Einwahldaten ein. Bei
                teleclinic.video wählen sich Patienten unter{' '}
                <Link href="/lobby" textDecoration="underline">
                  diesem Link
                </Link>{' '}
                ein. Patienten werden gebeten neben der TAN auch ihren Namen
                einzugeben.
              </Text>
            </VStack>
            <VStack gap="4" textAlign="center">
              <Icon as={Chat} w="50px" h="auto" fill="primary.900" mb="2" />
              <Heading color="primary.900" as="h5" variant="headline.xs.medium">
                3. Videosprechstunde durchführen
              </Heading>
              <Text>
                Nach einem kurzen automatischen Techniktest werden Sie ins
                Online-Wartezimmer geführt. Ihre Ärztin oder Ihr Arzt schaltet
                sich mit Ihnen zusammen, sobald die Videosprechstunde beginnen
                kann. Ist diese beendet, können Arzt und Patient das Fenster
                schließen.
              </Text>
            </VStack>
          </SimpleGrid>
          <Text align="center">
            Weitere Informationen zum Ablauf der Videosprechstunde finden Sie
            beispielsweise auf der{' '}
            <Link
              href="https://www.kbv.de/html/videosprechstunde.php"
              textDecoration="underline"
            >
              Website
            </Link>{' '}
            der Kassenärztlichen Bundesvereinigung.
          </Text>
        </VStack>
      </Section>

      {/* DOCTORS SECTION */}
      <Section id="doctors">
        <Heading
          mb={{ base: 8, md: 16 }}
          color="primary.900"
          align="center"
          variant="headline.lg.medium"
        >
          Für Ärztinnen und Ärzte
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap={{ base: 8, md: 16 }}
          alignItems="center"
        >
          <VStack align="start">
            <Heading color="primary.900" variant="headline.2xl.medium">
              Behandeln Sie Ihre Patienten einfach per Video
            </Heading>
            <Text mb="6" fontSize="xl">
              Sie sind Arzt und möchten auf unkomplizierte Weise mit einer
              zertifizierten Videosprechstunde Ihre Praxis ergänzen? Dann
              kontaktieren Sie uns gerne zur Vereinbarung eines kostenlosen
              Beratungstermins.
            </Text>
            <Flex
              gap="2"
              align="stretch"
              direction={{ base: 'column', md: 'row' }}
              alignSelf="stretch"
            >
              <ActionButton
                subtitle="Für Arztpraxen"
                title="Als Praxis registrieren"
                link="mailto:videosprechstunde@teleclinic.com"
              />
            </Flex>
            <Text fontSize="sm">
              Bereits registriert?{' '}
              <Link href="/signin" textDecoration="underline">
                Hier anmelden
              </Link>
              .
            </Text>
          </VStack>
          <Image
            order={{ base: -1, md: 'unset' }}
            src="/img/teleclinic-video-doctor.jpg"
            alt="Teleclinic Video Doctors image"
            borderRadius="2xl"
          />
        </SimpleGrid>
      </Section>

      {/* PATIENTS SECTION */}
      <Section bg="neutral.50" id="patients">
        <Heading
          mb={{ base: 8, md: 16 }}
          color="primary.900"
          align="center"
          variant="headline.lg.medium"
        >
          Für Patienten
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap={{ base: 8, md: 16 }}
          alignItems="center"
        >
          <Image
            src="/img/teleclinic-video-doctor-patient.jpg"
            alt="Teleclinic Video Patients image"
            borderRadius="2xl"
          />
          <VStack align="start">
            <Heading color="primary.900" variant="headline.2xl.medium">
              Videosprechstunde mit Ihrer Ärztin / Ihrem Arzt
            </Heading>
            <Text mb="6" fontSize="xl">
              Sie sind Patient und haben von Ihrem Arzt oder Behandler eine
              Einladung zu einer Videosprechstunde erhalten? Dann können Sie
              ohne Account der Videosprechstunde beitreten. Bitte halten Sie
              dafür Ihre Einwahldaten (TAN) bereit.
            </Text>
            <Flex
              gap="2"
              align="stretch"
              direction={{ base: 'column', md: 'row' }}
              alignSelf="stretch"
            >
              <ActionButton
                subtitle="Patient mit TAN-Einladung?"
                title="Zur Videosprechstunde"
                link="/lobby"
              />
            </Flex>
          </VStack>
        </SimpleGrid>
      </Section>

      <Section id="faq">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap={{ base: 8, md: 16 }}
          py="4"
        >
          <Heading color="primary.900" variant="headline.2xl.medium">
            Häufige Fragen zur Videosprechstunde
          </Heading>
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton>
                Wie funktioniert eine Videosprechstunde?
              </AccordionButton>
              <AccordionPanel pb={4}>
                Jede Praxis muss zunächst einen offiziell zugelassenen Anbieter
                mit der Durchführung der Videosprechstunde beauftragen. So wird
                sichergestellt, dass die Daten vor Zugriffen Dritter geschützt
                sind, unter anderem durch eine Ende-zu-Ende-Verschlüsselung.
                <br />
                <br />
                Ebenso zwingend erforderlich ist die schriftliche Einwilligung
                der Patienten und eine Aufklärung zum Thema Datenschutz. So ist
                es etwa verboten, das Gespräch per Bild und Ton ohne Zustimmung
                aufzuzeichnen.
                <br />
                <br />
                So läuft eine Videosprechstunde ab:
                <UnorderedList my="4" pl="4">
                  <ListItem>
                    Patienten erhalten eine Einladung von der Praxis mit
                    Einwahldaten zur Videosprechstunde
                  </ListItem>
                  <ListItem>
                    Die Software funktioniert im Internetbrowser, es muss nichts
                    heruntergeladen werden
                  </ListItem>
                  <ListItem>
                    Patienten gelangen über die Eingabe der Einwahldaten (TAN)
                    sowie Vor- und Nachnamen in die Videosprechstunde, sobald
                    der Behandler die Konsultation startet.
                  </ListItem>
                </UnorderedList>
                <br />
                So funktioniert die Videosprechstunde für Ärzte:
                <OrderedList my="4" pl="4">
                  <ListItem>
                    Melden Sie sich auf{' '}
                    <Link href="/signin" textDecoration="underline">
                      teleclinic.video
                    </Link>{' '}
                    mit Ihren Workspace-Zugangsdaten an
                  </ListItem>
                  <ListItem>
                    Erstellen Sie oder Ihre Praxisangestellten einen Termin für
                    Ihre Videosprechstunde
                  </ListItem>
                  <ListItem>
                    Ihre Patientinnen und Patienten erhalten per E-Mail eine
                    Videoeinladung und können dem Gespräch ohne Registrierung
                    beitreten
                  </ListItem>
                  <ListItem>
                    Führen Sie das Videogespräch mit Ihren Patientinnen und
                    Patienten zur verabredeten Zeit durch
                  </ListItem>
                  <ListItem>
                    Dokumentieren Sie die Behandlung in Ihrem PVS. Sofern es
                    bereits eine Behandlung vor Ort im selben Quartal
                    stattgefunden hat, muss die Behandlung nicht als
                    Videosprechstunde gekennzeichnet werden
                  </ListItem>
                </OrderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                Wie bekommen ich als Patient eine Einladung?
              </AccordionButton>
              <AccordionPanel pb={4}>
                Als Patient erhalten Sie von der Arztpraxis einen Termin und die
                Einwahldaten (TAN) für die Videosprechstunde. Ob Ihre Ärztin
                oder Ihr Arzt Videosprechstunden anbietet erfahren Sie in der
                Arztpraxis.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                Wer kann die Videosprechstunde nutzen?
              </AccordionButton>
              <AccordionPanel pb={4}>
                Die teleclinic.video Videosprechstunde ist für alle Erbringer
                gesundheitlicher Leistungen geeignet, insbesondere für Ärztinnen
                und Ärzte.
                <br />
                <br />
                Patienten dieser Leistungserbringer können die Videosprechstunde
                auf Einladung des jeweiligen Leistungserbringers nutzen.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                Benötigen Patienten einen Account?
              </AccordionButton>
              <AccordionPanel pb={4}>
                Nein, Patienten können teleclinic.video alleine mit den von der
                Praxis geteilten Einwahldaten (TAN) nutzen. Patienten werden
                außerdem gebeten ihren Vor- und Nachnamen vor dem betreten der
                Videosprechstunde einzugeben.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>Benötigen Ärzte einen Account?</AccordionButton>
              <AccordionPanel pb={4}>
                Ja, Ärzte oder andere Leistungserbringer müssen sich für
                teleclinic.video registrieren, um Videosprechstunden darüber
                anbieten zu können.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                Welche Technik brauche ich für eine Videosprechstunde?
              </AccordionButton>
              <AccordionPanel pb={4}>
                Damit eine Videosprechstunde problemlos ablaufen kann, müssen
                folgende Voraussetzungen erfüllt sein:
                <UnorderedList my="4" pl="4">
                  <ListItem>Computer, Laptop, Tablet oder Smartphone</ListItem>
                  <ListItem>Webcam oder integrierte Kamera</ListItem>
                  <ListItem>Mikrofon und Lautsprecher</ListItem>
                  <ListItem>Stabile und schnelle Internetverbindung</ListItem>
                </UnorderedList>
                Die meisten gängigen, modernen Endgeräte erfüllen die
                Voraussetzungen für eine Videosprechstunde.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                Wie Unterscheidet sich TeleClinic von teleclinic.video?
              </AccordionButton>
              <AccordionPanel pb={4}>
                teleclinic.video ist eine zertifizierte, web-basierte Software
                für Videosprechstunden zwischen Ärzten und Patienten der
                TeleClinic GmbH. teleclinic.video ermöglicht Patienten ohne
                Account an Videosprechstunden mit Ärzten teilzunehmen. Ärzte
                können über teleclinic.video mit ihren Patienten
                Videosprechstunden durchführen.
                <br />
                <br />
                Daneben ist teleclinic.video als Videosprechstunde in die
                TeleClinic Gesundheitsplattform integriert, über die in
                Deutschland zugelassene Ärztinnen und Ärzte, MVZ und andere
                Gesundheitsdienstleister Leistungen erbringen können. TeleClinic
                selbst ist kein Gesundheitsdienstleister, insbesondere kein
                Arzt, kein MVZ und keine Apotheke.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </SimpleGrid>
      </Section>

      {/* FOOTER SECTION */}
      <Section bg="primary.500">
        <VStack align="center" gap="8">
          <Icon as={LogoLockUp} w="234px" h="auto" fill="neutral.0" />
          <Text color="neutral.0" fontSize="xl" align="center">
            Zertifizierte Videosprechstunde zwischen Ärzten und Patienten
          </Text>
          <Divider borderColor="neutral.0" my="8" />
          <Flex
            gap="4"
            alignSelf="stretch"
            justify="space-between"
            align={{ base: 'center', md: 'baseline' }}
            direction={{ base: 'column', md: 'row' }}
          >
            <Icon as={Logo} w="120px" h="auto" fill="neutral.0" />
            <Flex
              gap={{ base: 0, md: 4 }}
              align={{ base: 'center', md: 'baseline' }}
              direction={{ base: 'column', md: 'row' }}
              fontSize="sm"
              color="neutral.0"
              fontWeight="normal"
            >
              <Text>&copy; {moment().year()} TeleClinic GmbH</Text>
              <Flex gap="4">
                <Link href="/privacy">Datenschutzerklärung</Link>
                <Link href="/imprint">Impressum</Link>
              </Flex>
            </Flex>
          </Flex>
        </VStack>
      </Section>
    </Flex>
  )
}

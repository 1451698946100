import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { ReactComponent as ChevronRight } from 'assets/icons/ChevronRight.svg'
import { ReactComponent as MenuIcon } from 'assets/icons/Menu.svg'

export function ActionButton({ title, subtitle, link, scheme = 'primary' }) {
  return (
    <Button
      colorScheme={scheme}
      rightIcon={<Icon as={ChevronRight} fill="currentcolor" />}
      justifyContent="space-between"
      borderRadius="xl"
      as="a"
      href={link}
      minW="auto"
      pr="4"
    >
      <Flex direction="column" align="start" gap={1}>
        <Text fontSize="xs" fontWeight="normal">
          {subtitle}
        </Text>
        {title}
      </Flex>
    </Button>
  )
}

export function Section({ children, ...props }) {
  return (
    <Box px="8" py={{ base: 10, md: 20 }} {...props}>
      <Box maxW="1108px" m="auto">
        {children}
      </Box>
    </Box>
  )
}

export function Navigation() {
  const [isDesktop] = useMediaQuery('(min-width: 760px)')
  const sections = [
    { id: 'about', title: "So funktioniert's" },
    { id: 'doctors', title: 'Für Ärzte' },
    { id: 'patients', title: 'Für Patienten' },
    { id: 'faq', title: 'Häufige Fragen' },
  ]
  if (isDesktop) {
    return (
      <HStack gap="4" fontSize="sm" fontWeight="medium">
        {sections.map(({ id, title }) => (
          <Link key={id} href={`#${id}`}>
            {title}
          </Link>
        ))}
      </HStack>
    )
  }
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        minW="auto"
        variant="ghost"
        p="0.5em"
        icon={<Icon as={MenuIcon} w="20px" h="20px" />}
      />
      <MenuList>
        {sections.map(({ id, title }) => (
          <MenuItem key={id} as={Link} href={`#${id}`}>
            {title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
